<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="deleteDialogue" width="400">
      <v-card>
        <v-layout pa-4 wrap justify-center>
          <v-flex xs12>
            <span
              style="
                color: #000000;
                font-family: sofiaProSemibold;
                font-size: 18px;
              "
            >
              Do you want to delete this {{ dataType }}?
            </span>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              outlined
              tile
              block
              depressed
              dark
              color="#C62525"
              @click="deleteData"
              class="itemValue"
            >
              Yes
            </v-btn>
          </v-flex>
          <v-flex xs6 px-2 py-4>
            <v-btn
              tile
              block
              depressed
              dark
              color="#1FB436"
              @click="deleteDialogue = false"
              class="itemValue"
            >
              No
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-layout wrap justify-center v-if="storage && storage.length > 0">
      <v-flex xs12>
        <v-card outlined>
          <v-layout wrap justify-center py-2>
            <v-flex xs12 sm10>
              <v-layout wrap justify-start>
                <v-flex xs12>
                  <v-flex xs12 text-left >
                    <span class="itemValue">Age Categories</span>
                  </v-flex>
                  <v-flex xs12 sm12 text-left v-for="(item, index) in storage" :key="index">
                    <v-card class="pa-2 my-2">
                      <v-layout wrap>
                        <v-flex xs4>
                          <span class="itemValue">Category:</span>
                        </v-flex>
                        <v-flex xs8>
                          <span class="itemValue">{{ item.category }}</span>
                        </v-flex>
                        <v-flex xs4>
                          <span class="itemValue">Min Age:</span>
                        </v-flex>
                        <v-flex xs8>
                          <span class="itemValue">{{ item.min_age }}</span>
                        </v-flex>
                        <v-flex xs4>
                          <span class="itemValue">Max Age:</span>
                        </v-flex>
                        <v-flex xs8>
                          <span class="itemValue">{{ item.max_age }}</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 pa-4>
        <v-layout wrap justify-center>
          <v-flex xs12 lg5>
            <v-btn
              block
              tile
              small
              depressed
              outlined
              color="#FF1313"
              :ripple="false"
              @click="editData"
              class="itemValue"
            >
              <span style="color: #ff1313"> Edit </span>
            </v-btn>
          </v-flex>
          <v-flex xs12 lg2> </v-flex>
          <v-flex xs12 lg5>
            <v-btn
              block
              tile
              small
              depressed
              outlined
              color="#FF1313"
              :ripple="false"
              @click="deleteDialogue = true"
              class="itemValue"
            >
              <span style="color: #ff1313"> Delete </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["storage", "deleteUrl", "dataType","editId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      msg: null,
      deleteDialogue: false,
      banDialogue: false,
      showSnackBar: false,
      timeout: 5000,
      activateDialogue: false,
    };
  },
  methods: {
    editData() {
      this.$emit("stepper", {
        type: "Edit",
        editData: true,
        dynamicData: this.storage,
      });
    },
    addData() {
      this.$emit("stepper", {
        type: "Add",
        addData: true,
        dynamicData: this.storage,
      });
    },

    deleteData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: this.deleteUrl + this.editId,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.deleteDialogue = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$emit("stepper", {
              type: "Delete",
              getData: true,
              pageResponse: response.data,
            });
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>