<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-center>
        <span class="itemHeading"> Add New {{ dataType }} </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">Adult</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="ages[0].min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="ages[0].max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">SubAdult</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="ages[1].min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="ages[1].max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">Juvenile</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="ages[2].min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="ages[2].max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">Calf</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="ages[3].min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="ages[3].max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Add {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  props: ["storage", "dataType", "addUrl", "subItemId","animalId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      isExtent: false,
      name: null,
      extendTypes: ["Number", "Extend"],
      threatlevel: {
        high: [],
        medium: [],
        low: [],
      },
      animalTypes: [
        "Elephant",
        "Tiger",
        "Leopard",
        "Wild Pig",
        "Monkey",
        "Snake",
        "Others",
        "Malabar Giant Squirrel",
        "Gaur",
        "Sambar",
        "Spotted Deer",
        "Wild Dog",
        "Others",
      ],
      ScenarioType: [],
      scenario: "",
      ThreatLevelType: ["high", "medium", "low"],
      animaladdthreatlevel: "",
      animalEntries: [],
      ages: [
        { category: "Adult", min_age: null, max_age: null },
        { category: "SubAdult", min_age: null, max_age: null },
        { category: "Juvenile", min_age: null, max_age: null },
        { category: "Calf", min_age: null, max_age: null },
      ],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addUser: false,
      });
    },
    validateInput() {
      if (this.ages.some(age => age.min_age === null || age.max_age === null)) {
        this.msg = "Please Provide all age ranges";
        this.showSnackBar = true;
        return;
      }
      this.addDynamicData();
    },
    addDynamicData() {
      var data = {
        animalId: this.animalId,
        ages: this.ages,
      };
      axios({
        method: "post",
        url: this.addUrl,
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "New " + this.dataType + " Created";
            this.showSnackBar = true;
            this.name = null;
            this.isExtent = null;
            this.threatlevel = {
              high: [],
              medium: [],
              low: [],
            };
            this.animalEntries = this.ScenarioType.map(() => ({
              scenario: "",
              threatlevel: "",
            }));
            this.$emit("stepper", {
              type: "Add",
              getData: true,
              addData: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animalAge/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.ScenarioType = response.data.data;
            this.animalEntries = this.ScenarioType.map(() => ({
              scenario: "",
              threatLevel: "",
            }));
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
 .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }</style>
