<template>
  <div>
    <ServerError v-if="ServerError" />
    <PageLoader :storage="appLoading" />

    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Edit {{ dataType }}</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">Adult</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="adult_min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="adult_max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">SubAdult</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="subadult_min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="subadult_max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">Juvenile</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="juvenile_min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="juvenile_max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 lg3 pt-3>
                <span class="itemHeading">Calf</span>
              </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  type="number"
                  outlined
                  placeholder="Minimum Age"
                  dense
                  v-model="calf_min_age"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 lg1> </v-flex>
              <v-flex xs12 lg4>
                <v-text-field
                  color="#FDCE48"
                  class="formClass inputPrice"
                  outlined
                  type="number"
                  placeholder="Maximum Age"
                  dense
                  v-model="calf_max_age"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              <span style="color: #fff">Edit {{ dataType }}</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: ["storage", "dataType", "editUrl", "subItemId","editId"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
  
      adult_min_age: null,
      adult_max_age: null,
      subadult_min_age: null,
      subadult_max_age: null,
      juvenile_min_age: null,
      juvenile_max_age: null,
      calf_min_age: null,
      calf_max_age: null,
    };
  },
  beforeMount() {
    this.storage.forEach(item => {
      switch (item.category) {
        case "Adult":
          this.adult_min_age = item.min_age;
          this.adult_max_age = item.max_age;
          break;
        case "SubAdult":
          this.subadult_min_age = item.min_age;
          this.subadult_max_age = item.max_age;
          break;
        case "Juvenile":
          this.juvenile_min_age = item.min_age;
          this.juvenile_max_age = item.max_age;
          break;
        case "Calf":
          this.calf_min_age = item.min_age;
          this.calf_max_age = item.max_age;
          break;
      }
    });
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Edit",
        getData: false,
        editUser: false,
      });
    },
    validateInput() {
      if (
        !this.adult_min_age || !this.adult_max_age ||
        !this.subadult_min_age || !this.subadult_max_age ||
        !this.juvenile_min_age || !this.juvenile_max_age ||
        !this.calf_min_age || !this.calf_max_age
      ) {
        this.msg = "Please fill all age fields";
        this.showSnackBar = true;
        return;
      } else {
        this.editdDynamicData();
      }
    },
    editdDynamicData() {
      const ages = [
        { category: "Adult", min_age: this.adult_min_age, max_age: this.adult_max_age },
        { category: "SubAdult", min_age: this.subadult_min_age, max_age: this.subadult_max_age },
        { category: "Juvenile", min_age: this.juvenile_min_age, max_age: this.juvenile_max_age },
        { category: "Calf", min_age: this.calf_min_age, max_age: this.calf_max_age },
      ];
      
      const data = {
        id: this.editId,
        ages: ages
      };
      
      axios({
        method: "post",
        url: this.editUrl, 
        data: data,
        headers: {
          'x-auth-token': localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = this.dataType + " Edited";
            this.showSnackBar = true;
  
            this.$emit("stepper", {
              type: "Edit",
              getData: true,
              editData: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
 .inputPrice input::-webkit-outer-spin-button,
  .inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }</style>
